import React, { useCallback, useState } from 'react';
import { XIcon } from '@kit/ui/icons/X';
import { Archive, Edit, Trash2 } from 'react-feather';
import { colors } from '@styles';
import { useConfirmModal } from '@common/PromiseModal';
import { useQueryClient } from 'react-query';
import { ReactQueryKey } from '@enums';
import { useProjectBulk } from '@hooks/useProjectBulk';
import { useRoutes } from '@hooks/useRoutes';
import { useAppSelector } from '@hooks/store';
import { selectCurrentUserId } from '@state/selectors';
import { useUserRoleSettings } from '@hooks/useRoles';
import { hasAccess } from '@utils/roles';
import { BulkModal as BulkModalProject } from '@components/Project/ProjectView/BulkModal';
import { BulkPanelProps } from '@common/BulkPanel';
import { useCompanyProperties } from '@hooks/useCompanyProperties';
import { PropertyType, RecordType } from '@types';
import { find } from 'lodash';
import { UnarchiveIcon } from '@kit/ui/icons/Unarchive';
import { Tooltip } from '@material-ui/core';
import { SelectedLabel, SelectionControlPanel } from './styled';

interface Props {
  selectedIds: number[];
  clearSelection: () => void;
  isAllSelectedArchived: boolean;
}

export const SelectionControl = ({ selectedIds, clearSelection, isAllSelectedArchived }: Props) => {
  const { companyId } = useRoutes();
  const userId = useAppSelector(selectCurrentUserId);
  const [isBulkEditModalShown, setIsBulkEditModalShown] = useState(false);
  const { data: access } = useUserRoleSettings(companyId, userId);
  const { allProperties } = useCompanyProperties();

  const queryClient = useQueryClient();

  const invalidateCache = useCallback(() => {
    queryClient.invalidateQueries([ReactQueryKey.WorkspaceAccounts]);
    queryClient.invalidateQueries([ReactQueryKey.ClientsListInitialGroupData]);
    queryClient.invalidateQueries([ReactQueryKey.ClientsByIds]);
    queryClient.invalidateQueries([ReactQueryKey.RecordDetail]);
  }, [queryClient]);

  const {
    update: { mutateAsync: bulkUpdateProjects },
    archive: { mutateAsync: bulkArchiveProjects },
    remove: { mutateAsync: bulkRemoveProjects }
  } = useProjectBulk(RecordType.ACCOUNT);

  const confirm = useConfirmModal();

  const handleBulkEditSubmit: BulkPanelProps['onSubmit'] = async (payload: any) => {
    if ('fieldId' in payload) {
      const property = find(allProperties, { id: payload.fieldId });

      await bulkUpdateProjects({
        projects: selectedIds,
        field:
          property?.type === PropertyType.Person && property.isAdditional
            ? {
                ...payload,
                value: payload.value ? { id: payload.value } : null
              }
            : payload
      });
    } else {
      await bulkUpdateProjects({
        projects: selectedIds,
        field: payload
      });
    }

    invalidateCache();
    clearSelection();

    setIsBulkEditModalShown(false);
  };

  const handleUnarchive = useCallback(async () => {
    if (await confirm({ confirmText: 'Are you sure you want to perform this action?' })) {
      await bulkArchiveProjects({
        projects: selectedIds,
        isArchive: false
      });

      invalidateCache();
      clearSelection();
    }
  }, [confirm, bulkArchiveProjects, invalidateCache, clearSelection, selectedIds]);

  const handleArchive = useCallback(async () => {
    if (await confirm({ confirmText: 'Are you sure you want to perform this action?' })) {
      await bulkArchiveProjects({
        projects: selectedIds,
        isArchive: true
      });

      invalidateCache();
      clearSelection();
    }
  }, [confirm, bulkArchiveProjects, invalidateCache, clearSelection, selectedIds]);

  const handleDelete = useCallback(async () => {
    if (await confirm({ confirmText: 'Are you sure you want to perform this action?' })) {
      await bulkRemoveProjects({
        projects: selectedIds
      });

      invalidateCache();
      clearSelection();
    }
  }, [confirm, bulkRemoveProjects, invalidateCache, clearSelection, selectedIds]);

  const handleEdit = useCallback(() => {
    setIsBulkEditModalShown(true);
  }, []);

  return (
    <SelectionControlPanel>
      <XIcon onClick={clearSelection} size="24px" color="#9C9CAA" />
      <SelectedLabel>{selectedIds.length} selected:</SelectedLabel>
      {hasAccess(access!, 'project', 'edit') && (
        <Tooltip title="Edit">
          <Edit onClick={handleEdit} size="16px" color={colors.green} />
        </Tooltip>
      )}
      {hasAccess(access!, 'project', 'edit') && !isAllSelectedArchived && (
        <Tooltip title="Archive">
          <Archive onClick={handleArchive} size="16px" color={colors.green} />
        </Tooltip>
      )}
      {isAllSelectedArchived && (
        <>
          {hasAccess(access!, 'project', 'edit') && (
            <Tooltip title="Unarchive">
              <UnarchiveIcon onClick={handleUnarchive} size="16px" color={colors.green} />
            </Tooltip>
          )}
          {hasAccess(access!, 'project', 'delete') && (
            <Tooltip title="Delete">
              <Trash2 onClick={handleDelete} size="16px" color={colors.green} />
            </Tooltip>
          )}
        </>
      )}

      {isBulkEditModalShown && (
        <BulkModalProject
          open
          recordType={RecordType.ACCOUNT}
          onClose={() => setIsBulkEditModalShown(false)}
          onSubmit={handleBulkEditSubmit}
          countBulk={selectedIds.length}
        />
      )}
    </SelectionControlPanel>
  );
};
