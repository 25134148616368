import { useQuery } from 'react-query';
import { ReactQueryKey } from '@enums';
import { errorHandler } from '@services/api/helpers';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { ProjectAdditional, RecordType } from '@types';
import { Project, ProjectsConnection } from '@generated/types/graphql';
import { useAppSelector } from '@hooks/store';
import { selectCurrentUserId, selectWorkspaceId } from '@state/selectors';
import { propertiesValuesToAdditional, getPrimaryEmailPhone } from '@utils/properties';

export type RecordDetail = Project & {
  deals: Project['projectsByParentProjectId'];
  projects: Project['projectsByParentProjectId'];
  account: Project['parentProject'];
  additional: ProjectAdditional;
  stageUpdates: Project['projectStageUpdates'];
};

type Options = {
  refetchOnMount?: boolean;
  isEnabled?: boolean;
};

export const useRecordDetail = (recordId?: number, { refetchOnMount, isEnabled = true }: Options = {}) => {
  const userId = useAppSelector(selectCurrentUserId);
  const companyId = useAppSelector(selectWorkspaceId);

  return useQuery<RecordDetail>(
    [ReactQueryKey.RecordDetail, recordId, companyId],
    async () => {
      try {
        const {
          projectsConnection: { nodes }
        } = await postGraphql<{
          projectsConnection: ProjectsConnection;
        }>(
          gql`
            query RECORD_DETAIL_QUERY($companyId: Int!, $projectId: Int!, $deal: String, $project: String) {
              projectsConnection(filter: { id: { equalTo: $projectId }, companyId: { equalTo: $companyId } }) {
                nodes {
                  id
                  uid
                  title
                  description
                  accountType
                  lastActivity
                  revenue
                  totalPrice
                  totalCost

                  createdByUser {
                    id
                    firstName
                    lastName
                    avatarUrl
                  }

                  referrerContact {
                    id
                    name
                    portalStatus
                  }

                  projectPropertiesValues(filter: { textValue: { isNull: false, notEqualTo: "" } }) {
                    columnId
                    textValue
                    numericValue
                    dateValue
                    workerValue
                    dropdownValue
                    dateValueAllDay
                    userByWorkerValue {
                      id
                      firstName
                      lastName
                      email
                      avatarUrl
                    }
                    column {
                      type
                      multiple
                    }
                    files {
                      id
                      name
                      type
                      metaData
                      annotations
                      downloadUrl
                      projectId
                      createdByUser {
                        id
                        firstName
                        lastName
                        email
                        avatarUrl
                      }
                    }
                  }
                  address
                  type
                  isActive
                  status
                  companyId
                  blueprintId
                  blueprint {
                    name
                    blueprintProjectStages(orderBy: POSITION_ASC) {
                      projectStage {
                        id
                        name
                        type
                      }
                    }
                  }
                  trades
                  privilege
                  createdAt
                  city
                  street
                  imageUrl
                  streetViewUrl
                  zipcode
                  state
                  jurisdiction {
                    uuid
                    id
                    name
                  }
                  projectContacts(orderBy: [PROJECT_CONTACTS_CONNECTION_MIN_POSITION_ASC]) {
                    emails
                    phones
                    id
                    name
                    status
                    createdAt
                    companyId
                    portalStatus
                    contactProjects {
                      id
                      title
                    }
                  }
                  projectMembers {
                    id
                    member {
                      avatarUrl
                      email
                      firstName
                      lastName
                      id
                    }
                  }
                  dealValue
                  dealSize
                  projectValue
                  projectSize
                  confidence
                  stage {
                    name
                    type
                    id
                  }

                  projectStageTimelinesByProjectId {
                    spentTime
                    isEnded
                    stage {
                      id
                      name
                      type
                    }
                  }

                  account: parentProject {
                    companyId
                    accountType
                    title
                    id
                    uid
                    createdAt
                    city
                    street
                    zipcode
                    state
                    projectPropertiesValues(filter: { textValue: { isNull: false, notEqualTo: "" } }) {
                      columnId
                      textValue
                      numericValue
                      dateValue
                      workerValue
                      dropdownValue
                      dateValueAllDay
                      userByWorkerValue {
                        id
                        firstName
                        lastName
                        email
                        avatarUrl
                      }
                      column {
                        type
                        multiple
                      }
                      files {
                        id
                        name
                        type
                        metaData
                        annotations
                        downloadUrl
                        projectId
                      }
                    }
                    type
                    stage: stage {
                      name
                      type
                      id
                    }
                    companyId
                    projectContacts: projectContacts(orderBy: [PROJECT_CONTACTS_CONNECTION_MIN_POSITION_ASC]) {
                      emails
                      phones
                      id
                      name
                      status
                      createdAt
                      companyId
                      contactProjects {
                        id
                        title
                      }
                    }
                    accountStatus
                    isActive
                  }
                  deals: projectsByParentProjectId(orderBy: [CREATED_BY_ASC], filter: { type: { equalTo: $deal } }) {
                    dealValue
                    dealSize
                    projectValue
                    projectSize
                    confidence
                    title
                    id
                    uid
                    createdAt
                    address
                    city
                    street
                    zipcode
                    state
                    requestStatus
                    projectPropertiesValues(filter: { textValue: { isNull: false, notEqualTo: "" } }) {
                      columnId
                      textValue
                      numericValue
                      dateValue
                      workerValue
                      dropdownValue
                      dateValueAllDay
                      userByWorkerValue {
                        id
                        firstName
                        lastName
                        email
                        avatarUrl
                      }
                      column {
                        type
                        multiple
                      }
                      files {
                        id
                        name
                        type
                        metaData
                        annotations
                        downloadUrl
                      }
                    }
                    companyId
                    type
                    trades
                    parentProjectId
                    stage: stage {
                      name
                      type
                      id
                    }
                    projectContacts: projectContacts(orderBy: [PROJECT_CONTACTS_CONNECTION_MIN_POSITION_ASC]) {
                      emails
                      phones
                      id
                      name
                      status
                      createdAt
                      companyId
                    }
                    isActive
                    privilege
                  }
                  projects: projectsByParentProjectId(
                    orderBy: [CREATED_BY_ASC]
                    filter: { type: { equalTo: $project } }
                  ) {
                    confidence
                    title
                    id
                    uid
                    createdAt
                    address
                    city
                    street
                    zipcode
                    state
                    imageUrl
                    streetViewUrl
                    projectPropertiesValues(filter: { textValue: { isNull: false, notEqualTo: "" } }) {
                      columnId
                      textValue
                      numericValue
                      dateValue
                      workerValue
                      dropdownValue
                      dateValueAllDay
                      userByWorkerValue {
                        id
                        firstName
                        lastName
                        email
                        avatarUrl
                      }
                      column {
                        type
                        multiple
                      }
                      files {
                        id
                        name
                        type
                        metaData
                        annotations
                        downloadUrl
                        projectId
                      }
                    }
                    companyId
                    type
                    trades
                    stage: stage {
                      name
                      type
                      id
                    }
                    projectContacts: projectContacts(orderBy: [PROJECT_CONTACTS_CONNECTION_MIN_POSITION_ASC]) {
                      emails
                      phones
                      id
                      name
                      status
                      createdAt
                      companyId
                    }
                    isActive
                    status
                  }
                  ownerId
                  owner {
                    firstName
                    lastName
                    avatarUrl
                    email
                    id
                  }
                  salesRep {
                    firstName
                    lastName
                    avatarUrl
                    email
                    id
                  }
                  projectManager {
                    firstName
                    lastName
                    avatarUrl
                    email
                    id
                  }
                  projectStageUpdates(orderBy: CREATED_AT_DESC, first: 1) {
                    id
                    stage {
                      name
                      id
                      redSla
                      yellowSla
                    }
                    createdAt
                  }
                  stageUpdates: projectStageUpdates(orderBy: CREATED_AT_ASC) {
                    stage {
                      id
                    }
                    createdAt
                  }
                  accountStatus
                  requestStatus
                  lastInboundCallDisposition
                  lastInboundCallTime
                  lastInboundEmailTime
                  lastInboundSmsTime
                  lastOutboundCallDisposition
                  lastOutboundCallTime
                  lastOutboundEmailTime
                  lastOutboundSmsTime
                  totalInboundCallsCount
                  totalOutboundCallsCount
                }
              }
            }
          `,
          {
            companyId,
            projectId: recordId,
            deal: RecordType.DEAL,
            project: RecordType.PROJECT
          }
        );

        const [record] = nodes;

        if (!record) {
          throw new Error('Sorry, the project is deleted, or you have no access to it.');
        }

        record.additional = propertiesValuesToAdditional(record.projectPropertiesValues);
        if (record.account) {
          record.account.additional = propertiesValuesToAdditional(record.account.projectPropertiesValues);
        }
        record.deals.forEach((deal) => {
          // eslint-disable-next-line no-param-reassign
          deal.additional = propertiesValuesToAdditional(deal.projectPropertiesValues);
        });
        record.projects.forEach((project) => {
          // eslint-disable-next-line no-param-reassign
          project.additional = propertiesValuesToAdditional(project.projectPropertiesValues);
        });

        return { ...record, ...getPrimaryEmailPhone(record) };
      } catch (e) {
        throw errorHandler(e);
      }
    },
    {
      enabled: isEnabled && !!recordId && userId !== 0,
      refetchOnMount,
      retry: false
    }
  );
};
