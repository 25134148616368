import React, { useCallback, useRef } from 'react';
import { PrivilegedTask } from '@generated/types/graphql';
import { CopyButton } from '@common/ui';
import { parseUtcDate } from '@utils/dates';
import moment from 'moment';

import { WorkOrderTypeIcon } from '@common/WorkOrderTypeIcon';
import { Formik } from 'formik';
import { taskToFormDataAdapter, formToTaskDTO } from '@adapters/TaskModalAdapter';
import { taskUpdate } from '@services/KanbanServices/validations';
import { debounce, isEqual } from 'lodash';
import { Form } from '@common/index';
import { updateTask } from '@components/Calendar/redux/actions';
import { useQueryClient } from 'react-query';
import { useAppDispatch } from '@hooks/store';
import { ReactQueryKey } from '@enums';
import { hasEntityAccessNew } from '@utils/roles';
import { getGeoLink } from '@utils/getGeoLink';

import { Address } from '@kit/ui/anchors/Address';
import { PhoneNumber } from '@kit/ui/anchors/PhoneNumber';
import { Badge } from '@kit/ui/Badge';
import { RecordType } from '@types';
import { ProjectTitleChip } from '../ProjectTitleChip';
import {
  Container,
  Header,
  CreatedAt,
  InfoBlock,
  Content,
  PhoneWithCopy,
  PhonesList,
  Left,
  Right,
  HeaderLeft,
  HeaderRight,
  NotEditableInfo,
  FieldLabel,
  InstructionsTitle,
  ChecklistProgress,
  ChecklistCounter,
  CompletedAt,
  NumberAndTitle,
  ProjectMetaData,
  MetaData
} from './styled';

import { Activity } from './Activity';
import { CollaboratorsField } from './Fields/Collaborators';
import { Description } from './Fields/Description';
import { Title } from './Fields/Title';
import { Priority } from './Fields/Priority';
import { Labels } from './Fields/Labels';
import { Checklist } from './Fields/Checklist';
import { Forms } from './Fields/Forms';
import { DateTime } from './Fields/DateTime';
import { AssigneeField } from './Fields/Assignee';
import { Visits } from './Fields/Visits';
import { StatusButton } from './StatusButton';
import { Tracking } from './Tracking';
import { Actions } from './Actions';
import { useSubscribeToUpdates } from './useSubscribeToUpdates';

interface Props {
  workOrder: PrivilegedTask;
  onClose: () => void;
  onSaved?: (task: any) => void;
}

const schema = taskUpdate();

const isBeforeCheck = (dateBeforeToCompare: Date | null | undefined) => (dateToCheck: Date) => {
  if (!dateBeforeToCompare) {
    return true;
  }

  return moment(dateToCheck).isSameOrBefore(dateBeforeToCompare, 'date');
};

const isAfterCheck = (dateAfterToCompare: Date | null | undefined) => (dateToCheck: Date) => {
  if (!dateAfterToCompare) {
    return true;
  }

  return moment(dateToCheck).isSameOrAfter(dateAfterToCompare, 'date');
};

const FIELD_NAMES = [
  'title',
  'description',
  'priority',
  'assigneeId',
  'assignees',
  'labels',
  'isColored',
  'subTasks',
  'startDate',
  'startDateAllDay',
  'endDate',
  'endDateAllDay',
  'forms',
  'visits'
] as const;

const SubscribeToUpdates = ({ id, setValues, onClose }: { id: number; setValues: any; onClose: () => void }) => {
  const handleUpdate = useCallback(
    (updatedWorkOrder: any) => {
      setValues(taskToFormDataAdapter(updatedWorkOrder as any));
    },
    [setValues]
  );

  const handleRemove = useCallback(() => {
    onClose();
  }, [onClose]);

  useSubscribeToUpdates(id, handleUpdate, handleRemove);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};

export const WorkOrderView = ({ workOrder, onClose, onSaved }: Props) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const canEdit = hasEntityAccessNew(workOrder, 'edit');

  const previousSaved = useRef<any>(formToTaskDTO(taskToFormDataAdapter(workOrder)));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSubmit = useCallback(
    debounce(async (values, helpers) => {
      const update = formToTaskDTO(values);
      const diff = FIELD_NAMES.reduce((acc, fieldName) => {
        if (!isEqual(update[fieldName], previousSaved.current[fieldName])) {
          acc[fieldName] = update[fieldName];
        }

        return acc;
      }, {} as any);

      if (Object.keys(diff).length > 0) {
        previousSaved.current = update;

        queryClient.setQueryData([ReactQueryKey.Tasks, ReactQueryKey.TasksDetails, workOrder.id], (data) => {
          if (!data) {
            return data;
          }

          return {
            ...data,
            ...diff
          };
        });

        const result = await dispatch(
          updateTask({
            id: workOrder.id,
            isCompleted: workOrder.isCompleted,
            projectId: workOrder.project.id,
            ...diff
          })
        );

        queryClient.invalidateQueries([ReactQueryKey.Tasks]);
        queryClient.invalidateQueries([ReactQueryKey.ProjectsListInitialGroupData]);
        queryClient.invalidateQueries([ReactQueryKey.ProjectsByIds]);

        onSaved?.({
          id: workOrder.id,
          projectId: workOrder.project.id,
          isCompleted: workOrder.isCompleted
        });

        if (update.visits) {
          const hasNewId = update.visits.some((visit) => !visit.id);
          if (hasNewId) {
            helpers.setFieldValue('visits', result.visits);
            previousSaved.current.visits = result.visits;
          }
        }
      }

      helpers.setSubmitting(false);
    }, 1000),
    [dispatch, workOrder, queryClient]
  );

  const phones = workOrder.project?.projectContacts?.flatMap((contact) => contact.phones) ?? [];

  return (
    <Formik initialValues={taskToFormDataAdapter(workOrder as any)} validationSchema={schema} onSubmit={handleSubmit}>
      {({ values, setValues }) => (
        <Container>
          <SubscribeToUpdates id={workOrder.id} setValues={setValues} onClose={onClose} />
          <Form>
            <Header>
              <HeaderLeft>
                <StatusButton isReadOnly={!canEdit} workOrder={workOrder} onUpdated={onSaved} />
                {workOrder.completionDate && (
                  <CompletedAt>
                    Completed: {moment(parseUtcDate(workOrder.completionDate)).format('MM/DD/YY')}
                  </CompletedAt>
                )}
              </HeaderLeft>
              <HeaderRight>
                <Actions task={workOrder} size="20px" onDeleted={onClose} />
              </HeaderRight>
            </Header>
            <Content>
              <Left>
                <NumberAndTitle>
                  <div>#{workOrder.uid}: </div>
                  <Title isReadOnly={!canEdit} />
                </NumberAndTitle>

                <NotEditableInfo>
                  <ProjectMetaData gap={phones.length > 0 ? '20px' : '16px'}>
                    <ProjectTitleChip maxWidth="none" iconSize="16px" target="panel" project={workOrder.project} />
                    <div>
                      {workOrder.project.type === RecordType.PROJECT ? (
                        <Address
                          iconSize="16px"
                          value={workOrder.project.address[0]}
                          link={getGeoLink(workOrder.project.geoLocation, workOrder.project.address)}
                        />
                      ) : (
                        <Address iconSize="16px" value={workOrder.address} link={getGeoLink(null, workOrder.address)} />
                      )}
                    </div>
                    {phones.length > 0 && (
                      <PhonesList>
                        {phones.map((phone) => (
                          <PhoneWithCopy key={phone}>
                            <PhoneNumber iconSize="16px" value={phone} />
                            <CopyButton value={phone} />
                          </PhoneWithCopy>
                        ))}
                      </PhonesList>
                    )}
                  </ProjectMetaData>

                  <MetaData>
                    <div>
                      <Badge bgColor="white" color="#1D1D35">
                        <WorkOrderTypeIcon isField={workOrder.isField} />
                        {workOrder.isField ? 'Field' : 'Office'} Work Order
                      </Badge>
                    </div>
                    <CreatedAt>
                      Created: {moment(parseUtcDate(workOrder.createdAt)).format('MM/DD/YYYY h:mma').replace(':00', '')}
                    </CreatedAt>
                  </MetaData>
                </NotEditableInfo>

                <InfoBlock centered>
                  <FieldLabel>Priority</FieldLabel>
                  <Priority isReadOnly={!canEdit} />
                </InfoBlock>

                <InfoBlock>
                  <FieldLabel fixPadding>Assignee</FieldLabel>
                  <AssigneeField isReadOnly={!canEdit} />
                </InfoBlock>

                <InfoBlock>
                  <FieldLabel fixPadding>Collaborators</FieldLabel>
                  <CollaboratorsField isReadOnly={!canEdit} />
                </InfoBlock>

                <InfoBlock>
                  <FieldLabel fixPadding>Labels</FieldLabel>
                  <Labels isReadOnly={!canEdit} />
                </InfoBlock>

                <InfoBlock block>
                  <FieldLabel>
                    <InstructionsTitle>
                      <div>Instructions</div>
                      <ChecklistCounter hidden={values.subTasks.length === 0}>
                        {values.subTasks?.length > 0
                          ? `${values.subTasks.filter((task) => task.isCompleted).length}/${values.subTasks.length} items
                        completed`
                          : 'No items'}
                      </ChecklistCounter>
                    </InstructionsTitle>
                    <ChecklistProgress hidden={values.subTasks.length === 0}>
                      <progress
                        max="100"
                        value={
                          values.subTasks?.length > 0
                            ? (values.subTasks.filter((task) => task.isCompleted).length / values.subTasks.length) * 100
                            : 0
                        }
                      />
                    </ChecklistProgress>
                  </FieldLabel>
                  <Description isReadOnly={!canEdit} />

                  <Checklist isReadOnly={!canEdit} />
                </InfoBlock>

                {workOrder.isField && (
                  <InfoBlock block>
                    <FieldLabel>Visits</FieldLabel>
                    <Visits isReadOnly={!canEdit} />
                  </InfoBlock>
                )}

                <InfoBlock centered>
                  <FieldLabel>Start date</FieldLabel>
                  <DateTime
                    fieldName="startDate"
                    allDayFieldName="startDateAllDay"
                    filterDate={isBeforeCheck(values.endDate)}
                    isReadOnly={!canEdit}
                  />
                </InfoBlock>
                <InfoBlock centered>
                  <FieldLabel>Due date</FieldLabel>
                  <DateTime
                    fieldName="endDate"
                    allDayFieldName="endDateAllDay"
                    filterDate={isAfterCheck(values.startDate)}
                    isReadOnly={!canEdit}
                  />
                </InfoBlock>

                <InfoBlock block>
                  <FieldLabel>Forms</FieldLabel>
                  <Forms workOrder={workOrder} isReadOnly={!canEdit} />
                </InfoBlock>

                <InfoBlock block>
                  <FieldLabel>Tracking</FieldLabel>
                  <Tracking taskId={workOrder.id} />
                </InfoBlock>
              </Left>
              <Right>
                <Activity workOrder={workOrder} />
              </Right>
            </Content>
          </Form>
        </Container>
      )}
    </Formik>
  );
};
