import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ReactQueryKey } from '@enums';
import { errorHandler } from '@services/api/helpers';
import contactsAPI from '@services/api/contactsAPI';
import { ContactDTO, CreateContactDto, Search, UpdateContactDto } from '@types';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { Contact, ContactsConnection, Project } from '@generated/types/graphql';
import { apiErrorHandler } from '@utils';
import { sortBy, uniqBy } from 'lodash';
import { selectWorkspaceId } from '@state/selectors';
import { useToast } from './useToast';
import { useAppSelector } from '.';

export type ContactWithRelated = Contact & {
  contactAccounts: Project[];
  contactProjects: Project[];
  contactDeals: Project[];
};

export type ContactsSearch = Partial<Search> & {
  sortCol: 'createdAt' | 'name';
};

/**
 * Returns first 10 contacts matching given title
 */
export const useContactsByQuery = (companyId: number, query: string) =>
  useQuery<Contact[]>(
    [ReactQueryKey.WorkspaceContacts, companyId, query],
    async () => {
      const variables = {
        companyId,
        query
      };

      try {
        return (
          await postGraphql<{ contactsConnection: ContactsConnection }>(
            gql`
              query CONTACTS_SEARCH_QUERY($companyId: Int!, $query: String) {
                contactsConnection(
                  filter: { companyId: { equalTo: $companyId }, searchString: { includesInsensitive: $query } }
                  first: 10
                ) {
                  nodes {
                    id
                    name
                    emails
                    phones
                    companyId
                    contactProjects {
                      id
                    }
                  }
                }
              }
            `,
            variables
          )
        ).contactsConnection.nodes;
      } catch (e) {
        throw apiErrorHandler('Error fetching contacts list', e);
      }
    },
    {
      enabled: !!companyId && !!query,
      initialData: [],
      keepPreviousData: true
    }
  );

type RelatedContactsRecord = {
  project: {
    contacts?: Contact[];
    children: { childrenContacts: Contact[] }[];
  };
};

export const useRelatedContacts = (companyId: number, recordId: number) =>
  useQuery(
    [ReactQueryKey.WorkspaceContacts, companyId, `useRelatedContacts-${recordId}`],
    async () => {
      try {
        const related = await postGraphql<RelatedContactsRecord>(
          gql`
            query RELATED_CONTACTS_QUERY($recordId: Int!) {
              project(id: $recordId) {
                contacts: projectContacts {
                  id
                  companyId
                  name
                  emails
                  phones
                }
                children: projectsByParentProjectId {
                  childrenContacts: projectContacts {
                    id
                    companyId
                    name
                    emails
                    phones
                  }
                }
              }
            }
          `,
          { recordId }
        );

        return sortBy(
          uniqBy(
            [
              ...(related.project.contacts || []),
              ...(related.project.children?.flatMap((child) => child.childrenContacts) || [])
            ],
            'id'
          ),
          'id'
        );
      } catch (e) {
        throw apiErrorHandler('Error fetching contacts list', e);
      }
    },
    {
      enabled: !!companyId && !!recordId,
      initialData: []
    }
  );

export const useContactMutations = () => {
  const queryClient = useQueryClient();

  const companyId = useAppSelector(selectWorkspaceId);

  const { showSuccess, showError } = useToast();

  const createMutation = useMutation<ContactDTO, Error, { dto: CreateContactDto }>(
    async ({ dto }) => {
      try {
        return (await contactsAPI.create(companyId, dto)).data;
      } catch (e) {
        throw errorHandler(e);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ReactQueryKey.WorkspaceContacts);
        showSuccess('Contact created');
      }
    }
  );

  const updateMutation = useMutation<ContactDTO, Error, { id: number; dto: UpdateContactDto }>(
    async ({ id, dto }) => {
      try {
        return (await contactsAPI.update(companyId, id, dto)).data;
      } catch (e) {
        throw errorHandler(e);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ReactQueryKey.WorkspaceContacts);
        showSuccess('Contact updated');
      }
    }
  );

  const deleteMutation = useMutation<void, Error, number>(
    async (id) => {
      try {
        await contactsAPI.remove(companyId, id);
      } catch (e) {
        throw errorHandler(e);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(ReactQueryKey.WorkspaceContacts);
        showSuccess('Contact deleted');
      }
    }
  );

  const inviteToPortal = useMutation<void, Error, number>(
    async (id) => {
      try {
        await contactsAPI.inviteToPortal(companyId, id);
      } catch (e) {
        throw errorHandler(e);
      }
    },
    {
      onSuccess: () => {
        showSuccess('Invitation sent');
      }
    }
  );

  const createContactPreviewToken = useMutation<string, Error, number>(
    async (id) => {
      try {
        const { data } = await contactsAPI.getPreviewToken(companyId, id);

        return data.accessToken;
      } catch (e) {
        throw errorHandler(e);
      }
    },
    {
      onError: (error) => {
        showError(error.message);
      }
    }
  );

  return {
    create: createMutation,
    update: updateMutation,
    delete: deleteMutation,
    inviteToPortal,
    createContactPreviewToken
  };
};
