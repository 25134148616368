import { DrilldownFilter, WidgetSettings } from '@features/Analytics/types';
import { postGraphql } from '@services/api/base/graphql';
import {
  ProjectReport,
  ProjectReportCondition,
  ProjectReportFilter,
  ProjectReportsConnection
} from '@generated/types/graphql';
import { gql } from 'graphql-request';
import { Property } from '@types';
import { DeepPartial } from 'redux';
import {
  buildRequestParams,
  buildSubmeasureCondition,
  buildGroupByProductCondition,
  buildTimelineCondition,
  buildTimelineFilter
} from './helpers';
import { FetchDataPreviewFn } from '../types';

export const fetchRecordsDataPreview: FetchDataPreviewFn<
  ProjectReport,
  ProjectReportFilter,
  ProjectReportCondition
> = async ({
  companyId,
  settings,
  page,
  perPage,
  propertiesMap,
  stageIdsByWorkflowMap,
  drilldownFilter
}: {
  companyId: number;
  settings: WidgetSettings;
  page: number;
  perPage: number;
  propertiesMap: Record<number, Property>;
  stageIdsByWorkflowMap: Record<number, number[]>;
  drilldownFilter?: DrilldownFilter<ProjectReportFilter, ProjectReportCondition>;
}) => {
  const filter: DeepPartial<ProjectReportFilter> = {
    ...buildRequestParams({ companyId, settings, propertiesMap }).filter,
    ...buildTimelineFilter(settings, stageIdsByWorkflowMap),
    ...(drilldownFilter?.filter ? drilldownFilter.filter : {})
  };

  const condition: DeepPartial<ProjectReportCondition> = {
    ...buildSubmeasureCondition(settings),
    ...buildGroupByProductCondition(settings),
    ...buildTimelineCondition(settings),
    ...(drilldownFilter?.condition ? drilldownFilter.condition : {})
  };

  const { projectReportsConnection } = await postGraphql<{ projectReportsConnection: ProjectReportsConnection }>(
    gql`
      query RECORDS_DATA_PREVIEW_QUERY(
        $filter: ProjectReportFilter!
        $condition: ProjectReportCondition!
        $offset: Int
        $first: Int
      ) {
        projectReportsConnection(filter: $filter, condition: $condition, offset: $offset, first: $first) {
          totalCount
          nodes {
            id
            completionTimeInS
            timelineStageStartedAt
            timelineStageEndedAt
            timelineStatusStartedAt
            timelineStatusEndedAt
            timelineStatusSpentTime
            timelineStageSpentTime
            timelineStatus
            timelineStage {
              id
              name
              type
            }

            project {
              id
              uid
              title
              description
              address
              city
              street
              zipcode
              state
              geoLocation
              createdAt
              completionDate
              progress
              homeOwnerEmail
              imageUrl
              isActive
              status
              isCompleted
              companyId
              ahj
              phone
              updatedAt
              streetViewUrl
              type
              dealValue
              dealSize
              projectValue
              projectSize
              accountType
              accountStatus
              confidence
              primaryEmail
              primaryPhone
              lastActivity
              overdueBy
              trades
              lastInboundCallDisposition
              lastInboundCallTime
              lastInboundEmailTime
              lastInboundSmsTime
              lastOutboundCallDisposition
              lastOutboundCallTime
              lastOutboundEmailTime
              lastOutboundSmsTime
              totalInboundCallsCount
              totalOutboundCallsCount
              createdByUser {
                id
                firstName
                lastName
                email
                avatarUrl
              }
              referrerContact {
                id
                name
                portalStatus
              }
              blueprint {
                id
                name
              }
              stage {
                id
                name
                type
              }
              owner {
                id
                firstName
                lastName
                email
                avatarUrl
              }
              salesRep {
                id
                firstName
                lastName
                email
                avatarUrl
              }
              projectManager {
                id
                firstName
                lastName
                email
                avatarUrl
              }
              jurisdiction {
                id
                uuid
                name
              }
              projectMembers {
                member {
                  id
                  firstName
                  lastName
                  email
                  avatarUrl
                }
              }

              projectPropertiesValues(filter: { textValue: { isNull: false, notEqualTo: "" } }) {
                columnId
                dateValueAllDay
                textValue
                numericValue
                dateValue
                workerValue
                dropdownValue
                userByWorkerValue {
                  id
                  firstName
                  lastName
                  email
                  avatarUrl
                }
                column {
                  type
                  multiple
                }
                files {
                  id
                  name
                  type
                  metaData
                  downloadUrl
                  projectId
                }
              }
              projectStageUpdates(orderBy: CREATED_AT_DESC, first: 1) {
                id
                stage {
                  name
                  id
                  redSla
                  yellowSla
                }
                createdAt
              }
              parentProjectId
              parentProject {
                id
                title
                type
              }
              requestStatus
            }
          }
        }
      }
    `,
    {
      filter,
      condition,
      offset: page * perPage,
      first: perPage
    }
  );

  return {
    reports: projectReportsConnection.nodes,
    page,
    totalCount: projectReportsConnection.totalCount
  };
};
