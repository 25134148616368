import React from 'react';
import { Form, Formik } from 'formik';
import { range, uniq } from 'lodash';
import styled from 'styled-components';

import { CancelButton, Modal, SubmitButton } from '@components/common/ModalMui';
import { SelectFormik } from '@common/ui';
import { AssigneesField, DateField } from '@common';
import { useBulkState } from '@hooks';
import { BulkTaskUpdateAction } from '@enums';
import { BulkUpdateTaskDto } from '@types';

enum BulkTaskAction {
  SHIFT,
  REPLACE_ASSIGNEES,
  ADD_ASSIGNEES,
  REPLACE_ASSIGNEE,
  START_DATE,
  END_DATE,
  ARCHIVE
}

const EDIT_TARGET_OPTIONS = [
  {
    id: BulkTaskAction.SHIFT,
    name: 'Shift start & due dates',
    rootAction: BulkTaskUpdateAction.SHIFT
  },
  {
    id: BulkTaskAction.REPLACE_ASSIGNEE,
    name: 'Replace assignee',
    singularWorkspace: true,
    rootAction: BulkTaskUpdateAction.UPDATE_PROPERTIES
  },
  {
    id: BulkTaskAction.REPLACE_ASSIGNEES,
    name: 'Replace collaborators',
    singularWorkspace: true,
    rootAction: BulkTaskUpdateAction.UPDATE_PROPERTIES
  },
  {
    id: BulkTaskAction.ADD_ASSIGNEES,
    name: 'Add collaborators',
    singularWorkspace: true,
    rootAction: BulkTaskUpdateAction.UPDATE_PROPERTIES
  },
  {
    id: BulkTaskAction.START_DATE,
    name: 'Change start date',
    rootAction: BulkTaskUpdateAction.UPDATE_PROPERTIES
  },
  {
    id: BulkTaskAction.END_DATE,
    name: 'Change due date',
    rootAction: BulkTaskUpdateAction.UPDATE_PROPERTIES
  },
  {
    id: BulkTaskAction.ARCHIVE,
    name: 'Archive',
    rootAction: BulkTaskUpdateAction.UPDATE_PROPERTIES
  }
];

const SHIFT_DIRECTION_OPTIONS = [
  {
    id: 1,
    name: 'Forward'
  },
  {
    id: -1,
    name: 'Backward'
  }
];

const getOption = (value: number) => ({
  id: value,
  name: `${value}`
});

const SHIFT_DAYS_OPTIONS = [...range(0, 31), ...[45, 60, 75, 90]].map(getOption);

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  grid-template-rows: 1fr 1fr;
  padding-bottom: 30px;
`;

export const Item = styled.div`
  margin-bottom: 10px;
`;

type Values = {
  direction: -1 | 1;
  days: number;
  target: BulkTaskAction;
  startDate?: string;
  endDate?: string;
  assignees?: number[];
  assignee: number;
};

interface BulkModalTaskProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (dto: Omit<BulkUpdateTaskDto, 'ids'>) => Promise<void>;
  companyId?: number;
}

export const BulkModalTask: React.FC<BulkModalTaskProps> = (props) => {
  const { open, onClose, onSubmit, companyId: providedCompanyId } = props;
  const { selectedWithContext } = useBulkState();
  const companyIds = uniq(selectedWithContext.map((selectedWithContext) => selectedWithContext.companyId));
  const companyId = providedCompanyId ?? companyIds?.[0];
  const targetOptions =
    companyIds.length > 1
      ? EDIT_TARGET_OPTIONS.filter(({ singularWorkspace }) => !singularWorkspace)
      : EDIT_TARGET_OPTIONS;

  const initialValues = {
    target: targetOptions[0].id,
    direction: SHIFT_DIRECTION_OPTIONS[0].id,
    days: SHIFT_DAYS_OPTIONS[0].id,
    assignees: []
  } as Values;

  const handleSubmit = async (values: Values) => {
    const targetOption = targetOptions.find(({ id }) => id === values.target)!;

    return onSubmit({
      ...values,
      action: targetOption.rootAction,
      ...(values.target === BulkTaskAction.SHIFT ? { value: values.days * values.direction } : {}),
      ...(values.assignees ? { assignees: values.assignees.map(({ id }) => id) } : {}),
      ...(values.assignee ? { assigneeId: values.assignee?.id } : {}),
      ...(BulkTaskAction.REPLACE_ASSIGNEES === values.target ? { replaceOldValue: true } : {}),
      ...(BulkTaskAction.ARCHIVE === values.target ? { isArchived: true } : {}),
      ...(BulkTaskAction.START_DATE === values.target ? { startDateAllDay: values.startDateAllDay ?? true } : {}),
      ...(BulkTaskAction.END_DATE === values.target ? { endDateAllDay: values.endDateAllDay ?? true } : {})
    });
  };

  return (
    <Formik<typeof initialValues> initialValues={initialValues} onSubmit={handleSubmit}>
      {({ handleSubmit: $handleSubmit, values: { target } }) => {
        return (
          <Modal
            open={open}
            onClose={onClose}
            title="Bulk edit"
            footerButtons={[
              <CancelButton onClick={onClose} />,
              <SubmitButton data-analyticsid="updateBulkTasks" onClick={$handleSubmit} text="Save" />
            ]}
          >
            <Form>
              <Grid>
                <Item>
                  <SelectFormik label="Bulk edit type" options={targetOptions} name="target" />
                </Item>
                {target === BulkTaskAction.SHIFT && (
                  <>
                    <Item>
                      <SelectFormik label="Shift direction" options={SHIFT_DIRECTION_OPTIONS} name="direction" />
                    </Item>
                    <Item>
                      <SelectFormik label="Days" options={SHIFT_DAYS_OPTIONS} name="days" />
                    </Item>
                  </>
                )}
                {[BulkTaskAction.START_DATE, BulkTaskAction.END_DATE].includes(target) && (
                  <DateField
                    name={`${BulkTaskAction.START_DATE === target ? 'start' : 'end'}Date`}
                    label={`${BulkTaskAction.START_DATE === target ? 'Start' : 'End'} Date`}
                    showTimeSelect
                    allDayFieldName={`${BulkTaskAction.START_DATE === target ? 'start' : 'end'}DateAllday`}
                  />
                )}
                {[BulkTaskAction.ADD_ASSIGNEES, BulkTaskAction.REPLACE_ASSIGNEES].includes(target) && (
                  <Item>
                    <AssigneesField
                      name="assignees"
                      label="Assignees"
                      allowTeams
                      membersOnly={false}
                      companyId={companyId}
                    />
                  </Item>
                )}
                {[BulkTaskAction.REPLACE_ASSIGNEE].includes(target) && (
                  <Item>
                    <AssigneesField
                      isMultiple={false}
                      name="assignee"
                      label="Assignee"
                      allowTeams
                      membersOnly={false}
                      companyId={companyId}
                    />
                  </Item>
                )}
              </Grid>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};
