import { useQuery } from 'react-query';
import { DateTime } from 'luxon';
import { ReactQueryKey } from '@enums';
import { apiErrorHandler } from '@utils';
import { ChartPeriod, chartPeriodConfig } from '@hooks/systems/constants';
import { ChartPoint, EnergyPoint } from '@hooks/systems/types';
import fleetChartsAPI, {
  ChartDTO,
  Meter,
  SystemEnergy,
  SystemPower,
  SystemStorageEnergy,
  TotalEnergy
} from '@services/api/fleetChartsAPI';
import { groupBy } from 'lodash';

export type EnergyChart = {
  totalProduction: number | null;
  totalConsumption: number | null;
  totalExport: number | null;
  totalImport: number | null;
  totalCharge: number | null;
  totalDischarge: number | null;
  energy: EnergyPoint[];
  power: ChartPoint[];
};

export const useSystemHealthChart = (systemId: number, period: ChartPeriod, startAt: DateTime, endBefore: DateTime) =>
  useQuery<EnergyChart>({
    queryKey: [ReactQueryKey.System, 'useSystemHealthChart', systemId, { period, startAt, endBefore }],
    queryFn: async () => {
      try {
        const periodConfig = chartPeriodConfig[period];

        const chartDtos: ChartDTO[] = [
          {
            metric: 'energy',
            kind: 'total',
            meter: Meter.Production,
            startAt: startAt?.toISO(),
            endBefore: endBefore?.toISO(),
            period: periodConfig.totalGranularity.toISO()
          },
          {
            metric: 'energy',
            kind: 'points',
            meter: Meter.Production,
            startAt: startAt?.toISO(),
            endBefore: endBefore?.toISO(),
            period: periodConfig.pointGranularity.toISO()
          },
          {
            metric: 'energy',
            kind: 'total',
            meter: Meter.Consumption,
            startAt: startAt?.toISO(),
            endBefore: endBefore?.toISO(),
            period: periodConfig.totalGranularity.toISO()
          },
          {
            metric: 'energy',
            kind: 'points',
            meter: Meter.Consumption,
            startAt: startAt?.toISO(),
            endBefore: endBefore?.toISO(),
            period: periodConfig.pointGranularity.toISO()
          },
          {
            metric: 'energy',
            kind: 'total',
            meter: Meter.Export,
            startAt: startAt?.toISO(),
            endBefore: endBefore?.toISO(),
            period: periodConfig.totalGranularity.toISO()
          },
          {
            metric: 'energy',
            kind: 'total',
            meter: Meter.Import,
            startAt: startAt?.toISO(),
            endBefore: endBefore?.toISO(),
            period: periodConfig.totalGranularity.toISO()
          },
          {
            metric: 'power',
            kind: 'points',
            meter: Meter.Production,
            startAt: startAt?.toISO(),
            endBefore: endBefore?.toISO(),
            period: periodConfig.pointGranularity.toISO()
          },
          {
            metric: 'storageEnergy',
            kind: 'total',
            startAt: startAt?.toISO(),
            endBefore: endBefore?.toISO(),
            period: periodConfig.totalGranularity.toISO()
          }
        ];

        const { data: charts } = await fleetChartsAPI.getSystemsCharts({ systems: [systemId], charts: chartDtos });

        const [
          totalProduction,
          pointsProduction,
          totalConsumption,
          pointsConsumption,
          totalExport,
          totalImport,
          pointsPower,
          totalStorage
        ] = charts as [
          totalProduction: TotalEnergy,
          pointsProduction: SystemEnergy[],
          totalConsumption: TotalEnergy,
          pointsConsumption: SystemEnergy[],
          totalExport: TotalEnergy,
          totalImport: TotalEnergy,
          pointsPower: SystemPower[],
          totalStorage: SystemStorageEnergy
        ];

        const productionByTime = groupBy(pointsProduction, (point) => point.time);
        const consumptionByTime = groupBy(pointsConsumption, (point) => point.time);

        const energyByTime: { [time: string]: EnergyPoint } = {};
        Object.entries(productionByTime).forEach(([time, production]) => {
          if (!(time in energyByTime)) {
            energyByTime[time] = { time: DateTime.fromSQL(production[0].time) };
          }

          energyByTime[time].production = production[0].value;
        });
        Object.entries(consumptionByTime).forEach(([time, consumption]) => {
          if (!(time in energyByTime)) {
            energyByTime[time] = { time: DateTime.fromSQL(consumption[0].time) };
          }

          energyByTime[time].consumption = consumption[0].value;
        });

        return {
          totalProduction: totalProduction.value,
          totalConsumption: totalConsumption.value,
          totalExport: totalExport.value,
          totalImport: totalImport.value,
          totalCharge: totalStorage.charge,
          totalDischarge: totalStorage.discharge,
          energy: Object.entries(energyByTime)
            .map(([_, point]) => point)
            .toSorted((p1, p2) => +p1.time - +p2.time),
          power: pointsPower.map((node) => ({
            time: DateTime.fromSQL(node.time).toLocal(),
            power: node.value
          }))
        };
      } catch (e) {
        throw apiErrorHandler('Error fetching system energy', e);
      }
    },
    keepPreviousData: true,
    enabled: !!systemId,
    initialData: {
      totalConsumption: null,
      totalProduction: null,
      totalExport: null,
      totalImport: null,
      totalCharge: null,
      totalDischarge: null,
      energy: [],
      power: []
    }
  });
